import { Component, OnInit } from '@angular/core';
import { CarrouselDto } from '@bdo/carrousel';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

import { CoreFacade } from '../../core.facade';
import { CoreState } from '../../state/core.state';

/**
 * Componente Carrousel
 */
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
/**
 * Clase para el componente Carrousel
 */
export class CarouselComponent implements OnInit {
  /**
   * CoreFacade
   */
  private readonly facade: CoreFacade;
  /**
   * Spinner Service
   */
  public readonly spinner: NgxSpinnerService;
  public customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    center: true,
    dots: false,
    autoHeight: true,
    autoWidth: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  /**
   * CoreState
   */
  private readonly state: CoreState;
  /**
   * Observable carrousel
   */

  public carrousel$: Observable<CarrouselDto[]> = new Observable();
  public constructor(facade: CoreFacade, state: CoreState, spinner: NgxSpinnerService) {
    this.facade = facade;
    this.state = state;
    this.spinner = spinner;
    this.carrousel$ = this.state.carrousel$;
  }

  public ngOnInit(): void {
    this.spinner.show();
    this.facade.getCarrousel(() => {
      this.spinner.hide();
    });
  }
}
