import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RedirectGuard } from '../guards/redirect.guard';

import { CustomerPrivacyComponent } from './components/customer-privacy/customer-privacy.component';
import { EliteManagerComponent } from './components/elite-manager/elite-manager.component';
import { LayoutComponent } from './components/layout/layout.component';

/**
 * Routes
 */
const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
  },
  {
    path: 'customer-privacy',
    component: CustomerPrivacyComponent,
  },
  {
    path: 'elite-manager',
    component: EliteManagerComponent,
    canActivate: [RedirectGuard],
  },
];

/**
 * Imports/Exports
 */
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
