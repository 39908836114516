<div fxLayout.gt-md="row wrap" fxLayout.lt-lg="column" fxLayoutGap="0px">
  <div fxFlex.gt-md="65" fxFlex.lt-lg="100">
    <img class="occidente" src="./../../../../assets/img/conciliacion_landing banner.jpg" alt="" />
  </div>
  <div fxFlex.gt-md="32" fxFlex.lt-lg="100">
    <div class="padding-form" style="padding-top: 40px !important">
      <div fxLayout="row wrap">
        <div fxFlex="100">
          <h5 class="sub-title-customer-1 text-secondary font-weight-bold ml-1">Gerente Élite</h5>
          <h5 class="sub-title-customer-1 text-secondary font-weight-bold ml-1">Tu Banco te escucha</h5>
          <h5 class="sub-title-customer-2 text-secondary ml-1">Diligencia tus datos</h5>
          <h5 class="sub-title-customer-3 ml-1 text-primary mb-3">* Todos los campos son obligatorios</h5>
        </div>
        <div fxFlex="100">
          <form [formGroup]="formGroup" (ngSubmit)="onSubmitGe()">
            <!--Inicio Plantilla de Alert-->
            <ng-template #Alert let-warningOptions="warningOptions">
              <div *ngIf="warningOptions" class="alert">
                <div *ngIf="warningOptions?.showIconAlert" class="icon-alert">
                  <i class="bocc-alert-triangle"></i>
                </div>

                <div *ngIf="warningOptions?.texts" class="alert-caption">
                  <p *ngFor="let paragraph of warningOptions?.texts" [innerHTML]="paragraph"></p>
                </div>

                <div *ngIf="warningOptions?.showCloseAlert" class="icon-close">
                  <a class="btn-nano-gray-light">
                    <i class="bocc-x"></i>
                  </a>
                </div>
              </div>
            </ng-template>

            <!--Fin Plantilla de Alert-->

            <div fxLayout="column">
              <!--Documento-->
              <mat-form-field fxFlex="100%" hideRequiredMarker>
                <div class="form-group active">
                  <mat-label class="label">Número de Documento o NIT *</mat-label>
                  <input
                    class="mat-input-element-input"
                    matInput
                    type="text"
                    minlength="5"
                    maxlength="12"
                    formControlName="document"
                    placeholder="Digitos permitidos Min(5) - Max(12)."
                    [required]="formGroup.controls.document.invalid"
                    (keypress)="numberOnly($event)"
                    readonly
                  />
                </div>
              </mat-form-field>

              <!--Documento del cliente-->
              <mat-form-field fxFlex="100%" hideRequiredMarker>
                <div class="form-group active">
                  <mat-label class="label">Número de documento del cliente *</mat-label>
                  <input
                    class="mat-input-element-input"
                    matInput
                    type="text"
                    formControlName="documentClient"
                    minlength="5"
                    maxlength="12"
                    placeholder="Digitos permitidos Min(5) - Max(12)."
                    [required]="formGroup.controls.name.invalid"
                    (keypress)="numberOnly($event)"
                  />
                </div>
              </mat-form-field>

              <div
                *ngIf="
                  formGroup.controls.documentClient.value?.length <= 4 &&
                  formGroup.controls.documentClient.value?.length > 0
                "
                style="color: red"
              >
                El número de documento debe tener más de 4 dígitos.
              </div>

              <!--Número de teléfono-->
              <mat-form-field fxFlex="100%" hideRequiredMarker>
                <div class="form-group active">
                  <mat-label class="label">Número de Teléfono *</mat-label>
                  <input
                    class="mat-input-element-input"
                    matInput
                    type="number"
                    formControlName="phone"
                    placeholder="Digitos permitidos Max(10)."
                    [required]="formGroup.controls.name.invalid"
                    (keypress)="numberOnly($event)"
                    (input)="validatePhoneNumber()"
                  />
                  <mat-error *ngIf="formGroup.controls.phone.hasError('phoneNumberInvalid')">
                    El número de teléfono debe tener entre 7 y 10 dígitos.
                  </mat-error>
                </div>
              </mat-form-field>

              <!--Correo Electronico-->
              <mat-form-field fxFlex="100%" hideRequiredMarker>
                <div class="form-group active">
                  <mat-label class="label">Correo Electrónico</mat-label>
                  <input
                    class="mat-input-element-input"
                    matInput
                    type="email"
                    formControlName="email"
                    maxlength="128"
                    placeholder="(Valor  permitido:Ej. pruebas@pruebas.com)."
                    [ngClass]="{ invalid: formGroup.get('email')?.invalid && formGroup.get('email')?.touched }"
                  />
                  <!-- Mensaje de alerta -->
                  <div
                    *ngIf="formGroup.get('email')?.invalid && formGroup.get('email')?.touched"
                    class="alert-message invalid-text"
                  >
                    Por favor, ingresa un correo electrónico válido.
                  </div>
                </div>
              </mat-form-field>

              <!--Tipo de solicitud-->
              <mat-form-field fxFlex="100%" hideRequiredMarker>
                <div class="form-group active">
                  <mat-label class="label">Tipo de Solicitud *</mat-label>
                  <mat-select formControlName="typeOfRequest" (selectionChange)="onChangeTypeRequest($event.value)">
                    <mat-option *ngFor="let control of listTypeRequest" [value]="control.name">
                      {{ control.label }}
                    </mat-option>
                  </mat-select>
                </div>
              </mat-form-field>

              <!-- 'Request'-->
              <mat-form-field fxFlex="100%" hideRequiredMarker>
                <div class="form-group active">
                  <mat-label class="label">¿Cómo te podemos ayudar? *</mat-label>
                  <textarea
                    class="mat-input-element-textarea"
                    matInput
                    rows="3"
                    formControlName="textRequest"
                    minlength="10"
                    maxlength="750"
                    placeholder="Escribe tu solicitud aquí Min(10)-Máx(750) caracteres."
                    (keyup)="count()"
                    [ngClass]="{
                      'invalid-textarea': formGroup.get('textRequest')?.invalid && formGroup.get('textRequest')?.touched
                    }"
                  >
                  </textarea>

                  <!-- Mensaje de alerta -->
                  <div class="count-char">
                    <span>{{ this.valor }}|750</span>
                  </div>
                  <div
                    *ngIf="formGroup.get('textRequest')?.invalid && formGroup.get('textRequest')?.touched"
                    class="alert-message alert-text"
                  >
                    Por favor, ingresa como mínimo 10 caracteres.
                  </div>
                </div>
              </mat-form-field>

              <!-- Sección para cargue de archivos -->
              <div title="">
                <div
                  class="fileUpGroup"
                  attr.data-textButton="{{ messageButton }}"
                  attr.data-SelectFile="{{ messageSelectFile$ | async }}"
                >
                  <input
                    type="file"
                    class="fileUpload"
                    (change)="onFileChange($event)"
                    multiple
                    [ngClass]="{ 'invalid-input': validateFiles && !files.length }"
                  />
                </div>
                <div *ngIf="files.length > 0">
                  <div class="file-item" *ngFor="let file of files; index as i">
                    <p class="file-info">
                      {{ file.name }}
                      <button mat-icon-button class="delete-button" (click)="removeFile(i)">
                        <mat-icon>delete_forever</mat-icon>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
              <!-- Aquí se muestra el mensaje de advertencia debajo de la lista de archivos -->
              <div *ngIf="validateFiles">
                <p class="warning">{{ messageValidateFiles }}</p>
              </div>

              <!--Captcha-->
              <div class="form-group active">
                <re-captcha class="g-recaptcha center-align" formControlName="recaptchaReactiveGe"></re-captcha>
              </div>
              <mat-error *ngIf="!(validRecaptcha$ | async) && tryRecaptcha"> ¡El reCaptcha es Inválido! </mat-error>
            </div>
            <div>
              <!--Botón de Enviar-->
              <button mat-raised-button [disabled]="formGroup.invalid" class="button-border">
                <p [ngClass]="formGroup.invalid ? 'button button-disabled' : 'button button-primary'">Enviar</p>
              </button>
            </div>
          </form>
          <ngx-spinner bdColor="rgba(0,0,0,0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
            <p class="spinner-text">Cargando...</p>
          </ngx-spinner>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(107, 109, 134, 0.68)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p class="spinner-text">Cargando...</p>
</ngx-spinner>
