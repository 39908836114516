import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard implements CanActivate {
  public constructor(private readonly router: Router) {}

  public canActivate(): boolean {
    // Verifica si la página fue recargada
    const isReloaded = window.performance.navigation.type === 1;

    if (isReloaded && !sessionStorage.getItem('redirected')) {
      // Si fue recargada y aún no se redirigió, realiza la redirección
      sessionStorage.setItem('redirected', 'true');
      this.router.navigate(['/']);

      return false; // Evita la carga de la ruta actual
    }

    // Si la página no fue recargada o ya se redirigió, permite la navegación
    sessionStorage.removeItem('redirected'); 
    return true;
  }
}
