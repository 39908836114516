import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CaptchaDto, InteractionDto, ReCaptchaDto } from '@bdo/interaction';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';
import Swal from 'sweetalert2';

import { DataDecryptMe } from '../../../dynamic-form/models/data-decrypt-me';
import { ValidateManagerElite } from '../../../dynamic-form/models/validate-manager-elite';
import { EncryptService } from '../../../shared/services/encyrpt/encrypt.service';
import { CoreFacade } from '../../core.facade';
import { FormCustomerPrivacy } from '../../models/form-customer-privacy';
import { Interaction } from '../../models/interaction';
import { Select } from '../../models/select';

/**
 * Componente Angular
 */
@Component({
  selector: 'app-customer-privacy',
  templateUrl: './customer-privacy.component.html',
  styleUrls: ['./customer-privacy.component.scss'],
})
/**
 * Clase customerPrivacyComponent
 */
export class CustomerPrivacyComponent implements OnInit {
  /**
   * Servicio encargado de interactuar con el servicio de encriptación
   */
  private readonly encryptService: EncryptService;
  /**
   * Creador de Formulario para ser expuesto
   */
  public formBuilder: FormBuilder;
  /**
   * Facade Core
   */
  public coreFacade: CoreFacade;
  /**
   * Bandera de si es cliente o no
   */
  public isClient = false;
  /**
   * Bandera de que se encarga de validar si se esta inicializando el formulario
   */
  public init = true;
  /**
   * Contiene los controles del formulario
   */
  public formGroup: FormGroup;
  /**
   * Observable con ReCaptcha
   */
  private readonly reCaptcha$: Observable<ReCaptchaDto>;
  /**
   * tryRecaptcha: Intento del Recatcha
   */
  public tryRecaptcha = false;
  /**
   * Método para exponer que el Recaptcha sea válido
   */
  public validRecaptcha$ = new BehaviorSubject(false);
  /**
   * Diálogo Popup con resultado del envío
   */
  public contactChannel: Select[];
  /**
   * Diálogo Popup con resultado del envío
   */
  public informationChannel: Select[];
  /**
   * Spinner Service
   */
  public readonly spinner: NgxSpinnerService;

  /**
   * Constructor para el componente CustomerPrivacyComponent
   * @param formBuilder Creador de Formulario para ser expuesto
   * @param coreFacade Facade Core
   * @param spinner Spinner Service
   */
  public constructor(
    formBuilder: FormBuilder,
    coreFacade: CoreFacade,
    spinner: NgxSpinnerService,
    encryptService: EncryptService,
  ) {
    this.formBuilder = formBuilder;
    this.coreFacade = coreFacade;
    this.encryptService = encryptService;
    this.formGroup = this.formBuilder.group({
      document: ['', [Validators.pattern(''), Validators.required, Validators.minLength(5), Validators.maxLength(12)]],
      contactChannel: ['', [Validators.required]],
      informationChannel: ['', [Validators.required]],
      recaptchaReactive: [null, Validators.required],
    });
    this.isClient = false;
    this.init = true;
    this.reCaptcha$ = this.coreFacade.reCaptcha$();
    this.reCaptcha$.subscribe((response) => this.onRecaptcha(response));
    this.spinner = spinner;

    this.contactChannel = [
      {
        id: 1,
        value: 'Llamadas telefónicas y WhatsApp',
      },
      {
        id: 2,
        value: 'Llamadas telefónicas',
      },
      {
        id: 3,
        value: 'WhatsApp',
      },
    ];

    this.informationChannel = [
      {
        id: 1,
        value: 'Correo electrónico y mensaje de texto (SMS)',
      },
      {
        id: 2,
        value: 'Correo electrónico',
      },
      {
        id: 3,
        value: 'Mensaje de texto (SMS)',
      },
    ];
  }

  /**
   * A callback method that is invoked immediately after the default change detector has checked the directive's data-bound properties for the first time,
   * and before any of the view or content children have been checked. It is invoked only once when the directive is instantiated.
   */
  public ngOnInit(): void {}

  /**
   * @description Método llamado por el evento keypress permite solo números
   *
   * @param event Información del evento
   */
  public numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  /**
   * Metodo encargado de validar si es cliente
   */
  public validateClient() {
    this.spinner.show();
    this.coreFacade.validateClient(1, this.formGroup.value.document, (data) => {
      const validateEm = JSON.stringify(data);
      const validateEmJson: DataDecryptMe = JSON.parse(validateEm);
      const dataDecrypt = this.encryptService.toDecrypt(validateEmJson.data);
      const dataDeserealized = JSON.parse(dataDecrypt) as ValidateManagerElite;
      this.init = false;
      this.isClient = dataDeserealized.isClient;
      this.spinner.hide();
    });
  }

  /**
   * Metodo encargado comenzar la validacion del Capchat
   */
  public onSubmit() {
    this.spinner.show();
    const data: CaptchaDto = {
      googletoken: this.formGroup.value.recaptchaReactive,
    };

    this.coreFacade.postRecaptcha(data);
  }

  /**
   * Metodo encargado de validar el recapchat y comenzar el guarda de la interaccion
   * @param reCaptcha Informacion de la validacion del Captcha
   */
  public onRecaptcha(reCaptcha: ReCaptchaDto) {
    this.spinner.show();
    this.tryRecaptcha = true;
    this.validRecaptcha$.next(reCaptcha.success || false);
    if (reCaptcha.isValidAuthentication) {
      const formCustomerPrivacy: FormCustomerPrivacy = {
        numberDocument: this.formGroup.value.document,
        contactChannel: this.formGroup.value.contactChannel.value,
        informationChannel: this.formGroup.value.informationChannel.value,
      };

      const interactionModel: Interaction = {
        IdInteraction: 1,
        GuId: Guid.create().toString(),
        FormCustomerPrivacy: formCustomerPrivacy,
        Date: new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString(),
      };

      const interactionDto: InteractionDto = {
        payload: `${JSON.stringify(interactionModel)}`.trim(),
        version: '1',
        idSequence: 2,
        idFiling: 1,
        publishDate: new Date(),
        typeForm: 2,
      };
      setTimeout(() => {
        this.coreFacade.saveInteraction(interactionDto, (responseInteraction) => {
          if (responseInteraction !== 'false') {
            this.spinner.hide();
            this.init = true;
            this.isClient = false;
            this.formGroup.reset();
            this.confirmationMessage();
          } else {
            this.spinner.hide();
            this.errorMessagge();
          }
        });
      }, 500);
    } else {
      this.spinner.hide();
      this.errorMessagge();
    }
  }

  /**
   * Metodo encargado de mostrar el mesaje de confirmacion del registro
   */
  public confirmationMessage() {
    Swal.fire({
      title: '',
      text: 'Tu selección ha sido tramitada con éxito, la información será actualizada en nuestro sistema en los próximos 15 días hábiles.',
      confirmButtonText: 'Aceptar',
    }).then(function() {
      window.location.reload();
    });
  }

  /**
   * Metodo encargado de mostrar mensaje de error
   */
  public errorMessagge() {
    Swal.fire({
      title: '',
      text: 'Lo sentimos, no se cargó correctamente la información, inténtalo nuevamente.',
      confirmButtonText: 'Aceptar',
    });
  }
}
