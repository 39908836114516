import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthenticationService } from './authentication.service';

/**
 * Interceptor encargado de agregar el token a la solicitud
 */
@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  /**
   * Servicio encargado de administrar la autenticación
   */
  private readonly authenticationService: AuthenticationService;

  /**
   * Crea una nueva instancia de @see AuthInterceptorService
   * @param authenticationService Servicio encargado de administrar la autenticación
   */
  public constructor(authenticationService: AuthenticationService) {
    this.authenticationService = authenticationService;
  }

  /**
   * Identifies and handles a given HTTP request.
   * @param req The outgoing request object to handle.
   * @param next The next interceptor in the chain, or the backend if no interceptors remain in the chain.
   * @returns An observable of the event stream.
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('api') && this.authenticationService.getAuthenticated()) {
      let headers = new HttpHeaders();

      if (req.body instanceof FormData) {
        headers = this.authenticationService.appendAuthorizationHeader(headers);
      } else {
        headers = this.authenticationService.getHeaders();
      }

      const request = req.clone({
        headers,
      });

      return next.handle(request);
    }

    return next.handle(req);
  }
}
