import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CaptchaDto, InteractionDto, ReCaptchaDto } from '@bdo/interaction';
import { SendEmailDto } from '@bdo/send-email';
import { environment } from 'apps/form-contact/src/environments/environment';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { DialogPopupComponent } from '../../../core/components/dialog-popup/dialog-popup.component';
import { CoreFacade } from '../../../core/core.facade';
import { TypeAttach } from '../../../core/models/type-attach';
import { FileData } from '../../../dynamic-form/models/file-data';
import { FormModel } from '../../../dynamic-form/models/form-model';
import { JsonFormControls, JsonFormData, JsonFormMetadata } from '../../../dynamic-form/models/json-form-data';
import { DataTransferService } from '../../../services/data-transfer.service';
import { TealiumUtagService } from '../../../tealium/utag.service';
import { Interaction } from '../../models/interaction-ge';
import { FormManagerElite } from '../../models/manager-elite-model';

/**
 * Componente Angular
 */
@Component({
  selector: 'app-elite-manager',
  templateUrl: './elite-manager.component.html',
  styleUrls: ['./elite-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * Clase EliteManagerComponent
 */
export class EliteManagerComponent implements OnInit, OnDestroy {
  /**
   * Archivos selecionados
   */
  public selectedFile: File[] = [];
  /**
   * Observable destroy
   */
  public destroy$: Subject<void> = new Subject<void>();
  /**
   * Creador de Formulario para ser expuesto
   */
  public formBuilder: FormBuilder;
  /**
   * Contiene los controles del formulario
   */
  public formGroup: FormGroup;
  /**
   * Diálogo Popup con resultado del envío
   */
  public dialogPopup: MatDialog;
  /**
   * Contiene los controles del formulario
   */
  private readonly initialFormControlNames: string[] = [];
  /**
   * Mínimo valor para documento
   */
  public readonly minValueDocument = 99999;
  /**
   * Mínimo número de caracteres por solicitud
   */
  public readonly minNumberCharPerRequest = 10;
  /**
   * Mínimo número de caracteres por solicitud
   */
  public readonly maxNumberCharPerRequest = 750;
  /**
   * Número de caracteres en la descripción
   */
  public valor = 0;
  /**
   * JsonFormData asociado al componente
   */
  @Input() public jsonFormData!: JsonFormData;
  /**
   * Observable con ReCaptcha
   */
  private readonly reCaptchaGe$: Observable<ReCaptchaDto>;
  /**
   * Facade Core
   */
  public coreFacade: CoreFacade;
  /**
   * Servicio encargado de transferir datos entre componentes
   */
  public dataTransferService: DataTransferService;
  /**
   * Nombre de Campo para Archivos
   */
  public readonly filesFieldname = 'files';
  /**
   * visibleInputFile: Variable de control para mostrar Input de tipo File
   */
  public visibleInputFile = true;
  /**
   * visibleInputNoClient: Variable de control para mostrar Input de tipo no cliente
   */
  public visibleInputNoClient = false;
  /**
   * tryRecaptcha: Intento del Recatcha
   */
  public tryRecaptcha = false;
  /**
   * errorTextRequestSubmit: Intento de envío con error
   */
  public errorTextRequestSubmit = false;
  /**
   * BehaviorSubject para mostrar archivo subido
   */
  private readonly BehaviorSubjectMessageSelectFile = new BehaviorSubject<string>('Seleccione su archivo');
  /**
   * messageSelectFile$ texto para mostrar archivo subido
   */
  public readonly messageSelectFile$ = this.BehaviorSubjectMessageSelectFile.asObservable();
  /**
   * messageButton texto para botón
   */
  public messageButton = 'Subir';
  /**
   * Lista de Archivos seleccionados
   */
  public selectedFiles?: FileList;
  /**
   * Arreglo con la información de los archivos.
   */
  public files: File[] = [];
  /**
   * Spinner Service
   */
  public readonly spinner: NgxSpinnerService;
  /**
   * Tealium
   */
  public readonly tealium: TealiumUtagService;
  /**
   * Bandera para validar estado de archivos
   */
  public validateFiles = false;
  /**
   * Mensaje de alerta de archivos
   */
  public messageValidateFiles = '';
  /**
   * Flag para visualizar el mensaje segun la compañia
   */
  public visibleMessage = false;
  /**
   * Flag para escoger mensaje segun la compañia
   */
  public message = false;
  /**
   * Lista desplegable Tipos de solicitud : Gerente Elite
   */
  public listTypeRequest: JsonFormControls[] = [];
  /**
   * Documento Gerente élite
   */
  public documentGe: any;

  /**
   * Método para verificar si se muestra Input de tipo File o no.
   */
  public get showInputFile(): boolean {
    return this.visibleInputFile;
  }
  /**
   * Método para exponer que el Recaptcha sea válido
   */
  public validRecaptcha$ = new BehaviorSubject(false);
  /**
   * @description Crea una nueva instancia de JsonFormComponent
   *
   * @param formBuilder Parámetro-Propiedad de tipo FormBuilder (uso interno)
   *
   * @param coreFacade Injecta la instancia de CoreFacade
   *
   * @param dataTransferService para transferir datos entre componentes
   */
  public constructor(
    formBuilder: FormBuilder,
    dialogPopup: MatDialog,
    coreFacade: CoreFacade,
    dataTransferService: DataTransferService,
    spinner: NgxSpinnerService,
    tealium: TealiumUtagService,
  ) {
    this.formBuilder = formBuilder;
    this.formGroup = this.formBuilder.group({});
    this.dialogPopup = dialogPopup;
    this.coreFacade = coreFacade;
    this.spinner = spinner;
    this.tealium = tealium;
    this.dataTransferService = dataTransferService;
    this.formGroup = this.buildFormGroup();
    for (const controlName of Object.keys(this.formGroup.controls)) {
      this.initialFormControlNames.push(controlName);
    }
    this.reCaptchaGe$ = this.coreFacade.reCaptchaGe$();

    dataTransferService.behaviorSubjectTypeAttach.subscribe((response) => this.updateFilesValidators(response));
    this.reCaptchaGe$.subscribe((response) => this.doRecaptchaReceivedGe(response));
    this.coreFacade.radicado$().subscribe((response) => {
      dataTransferService.radicado = response;
      this.dialogPopup.open(DialogPopupComponent, {
        disableClose: true,
      });
    });
    this.tealium.setConfig({
      account: environment.tealiumAccount,
      profile: environment.tealiumProfile,
      environment: environment.tealiumEnviroment,
    });
  }
  /**
   * @description A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive.
   */
  public ngOnInit(): void {
    // Importa el dato documento de json-form-component
    this.dataTransferService.documentGe.subscribe((document) => {
      this.formGroup.controls.document.setValue(document);
    });
    // Se consultan las tipologias para Gerente Élite
    this.coreFacade.getTypologiesGe(1, 4).subscribe((result) => {
      result.forEach((item) => {
        const itemObj = JSON.parse(item.payload ?? '{}');
        const obj: JsonFormData = itemObj;
        const traverse = (control: JsonFormControls) => {
          if (control.children && control.children.length > 0) {
            control.children.forEach((child) => traverse(child));
          } else {
            this.listTypeRequest.push(control);
          }
        };
        obj.controls.forEach((control) => traverse(control));
      });
      this.spinner.hide();
    });
  }
  /**
   * Metodo ngOnDestroy
   */
  public ngOnDestroy() {
    this.coreFacade.ngOnDestroy();
  }
  /**
   * @description Método encargado de crear el formulario
   */
  private buildFormGroup() {
    let internalFormGroup;

    internalFormGroup = this.formBuilder.group({
      document: ['', [Validators.minLength(5), Validators.maxLength(12)]],
      company: [''],
      typeClient: [''],
      textRequest: [
        '',
        [Validators.required, Validators.minLength(this.minNumberCharPerRequest), Validators.maxLength(750)],
      ],
      files: [''],
      typeOfRequest: ['', [Validators.required]],
      name: [''],
      email: ['', Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|org|net|edu|gov|mil|info|io|co|me|biz|xyz|us|uk|es|fr|de|jp|cn|br|au|ca|COM|ORG|NET|EDU|GOV|MIL|INFO|IO|CO|ME|BIZ|XYZ|US|UK|ES|FR|DE|JP|CN|BR|AU|CA)$')],
      documentClient: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(12)]],
      phone: ['', [Validators.required]],
      idTemplate: [''],
      idMailBox: [''],
      idAttachmentMode: [''],
      recaptchaReactiveGe: [null, Validators.required],
    });

    return internalFormGroup;
  }
  /**
   * Metodo para validar el correo
   */
  public emailValidator(control: FormControl) {
    const email = control.value.toLowerCase(); // Convertir a minúsculas
    const validExtensions = ['.com', '.org', '.edu', '.gov', '.net', '.es', '.co'];

    if (email && email.length > 0) {
      const validEmail = validExtensions.some((ext) => email.endsWith(ext));
      if (!validEmail) {
        return { invalidEmail: true };
      }
    }

    return null; // Devolver null si la validación es exitosa
  }

  /**
   * Servicio encargado de asignar los valores metadata
   */
  public onChangeTypeRequest(metadataGe: any) {
    // Extraer la metadata del payload según el tipo de solicitud seleccionado
    const metadataJson = this.listTypeRequest.find((control) => control.name === metadataGe);
    const metadaPayload = JSON.stringify(metadataJson?.metadata);
    const metadataDeserialized: JsonFormMetadata = JSON.parse(metadaPayload);

    // Asignar al formGroup los diferentes id encontrados en la metadata
    this.formGroup.controls.idAttachmentMode.setValue(Number(metadataDeserialized.idAttachmentMode));
    this.formGroup.controls.idMailBox.setValue(Number(metadataDeserialized.idMailbox));
    this.formGroup.controls.idTemplate.setValue(Number(metadataDeserialized.idTemplate));
    this.formGroup.controls.typeOfRequest.setValue(metadataGe);

    // Asignar el responseDays
    this.dataTransferService.subjectMetadata.next(metadataDeserialized);
  }

  /**
   * @description Método para actualizar los validators para el campo files del formulario
   *
   * @param typeAttach Argumento con la información para establecer o remover las validaciones
   */
  private updateFilesValidators(typeAttach: TypeAttach) {
    const filesControl = this.formGroup.get(this.filesFieldname);
    filesControl?.clearAsyncValidators();
    switch (typeAttach) {
      case TypeAttach.Required: {
        this.visibleInputFile = true;
        filesControl?.setValidators([Validators.required, Validators.minLength(5)]);
        break;
      }
      case TypeAttach.Optional: {
        this.visibleInputFile = true;
        break;
      }
      case TypeAttach.Disabled: {
        this.visibleInputFile = false;
        filesControl?.setValidators([Validators.maxLength(1)]);
        break;
      }
      default: {
        this.visibleInputFile = false;
        filesControl?.clearAsyncValidators();
      }
    }
    filesControl?.updateValueAndValidity();
  }

  /**
   * @description Método llamado para agregar validaciones a los campos name y email si es tercero
   */
  public addNewControl() {
    this.formGroup = this.formBuilder.group({
      ...this.formGroup.controls,
      name: ['', Validators.required],
    });
  }
  /**
   * @description Método llamado para remover validaciones a los campos name y email si no es tercero
   */
  public removeNewControl() {
    this.formGroup = this.formBuilder.group({
      ...this.formGroup.controls,
      name: [''],
    });
  }

  /**
   * Metodo encargado de validar la cantidad de dígitos en el número de teléfono
   */
  public validatePhoneNumber() {
    const phoneControl = this.formGroup.get('phone');
    const value = phoneControl?.value.toString() || '';

    if (value.length > 10) {
      phoneControl?.setValue(value.slice(0, 10)); // Limitar la longitud a 10 dígitos
    }

    if (value.length < 7) {
      phoneControl?.setErrors({ phoneNumberInvalid: true });
    } else {
      phoneControl?.setErrors(null); // No hay errores si la longitud está en el rango permitido
    }
  }

  /**
   * @description Método llamado por el evento keypress permite solo números
   */
  public numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }
  /**
   * @description Método llamado por el evento keyup, para contar el numero de caracteres
   */
  public count() {
    const form = this.formGroup.value;
    this.valor = form.textRequest.length;
  }
  /**
   * Metodo para el cargue de archivos
   */
  public onFileChange(event: any) {
    this.validateFiles = false;
    this.selectedFiles = event.target.files;

    const filesControl = this.formGroup.get(this.filesFieldname);
    if (this.selectedFiles && this.selectedFiles.length > 0) {
      let totalSize = this.calculateTotalSize(this.files);
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const file = this.selectedFiles[i];

        if (this.isValidFile(file)) {
          if (totalSize + file.size <= 25165824) {
            // Verificar si el tamaño total no excede 25 MB
            totalSize += file.size;
            this.readFileContent(file, filesControl);
          } else {
            this.validateFiles = true;
            this.messageValidateFiles = 'El tamaño total a cargar es mayor a 24 MB';
          }
        } else {
          this.validateFiles = true;
          this.messageValidateFiles = 'Los formatos de archivo deben ser JPG, PDF, TIFF, JPEG';
        }
      }
    }
  }
  /**
   * Metodo para calcular si el peso total de los archivos es valido
   */
  public calculateTotalSize(files: FileData[]): number {
    return files.reduce((acc, file) => acc + file.size, 0);
  }
  /**
   * Metodo encargado de validar el formato del archivo
   */
  public isValidFile(file: File): boolean {
    const allowedFormats = ['pdf', 'jpg', 'jpeg', 'tiff'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (fileExtension) {
      return allowedFormats.includes(fileExtension);
    }

    return false; // Devolver falso si no se puede determinar la extensión del archivo
  }
  /**
   * Función para leer el contenido del archivo seleccionado
   */
  public readFileContent(file: File, filesControl: AbstractControl | null) {
    // Verificar si el archivo con el mismo nombre ya está en la lista de archivos
    const existingFile = this.files.find((f) => f.name.toLowerCase() === file.name.toLowerCase());

    if (existingFile) {
      // Manejar el caso de archivo con el mismo nombre
      this.validateFiles = true;
      this.messageValidateFiles = 'Ya existe un archivo con el mismo nombre en la lista';
    } else {
      const reader = new FileReader();

      reader.onload = () => {
        // Convertir la extensión del archivo a minúsculas antes de añadirlo a la lista
        const fileNameParts = file.name.split('.');
        // Covertimos la extension en minusculas
        const extension = fileNameParts.pop()?.toLowerCase();
        // Reconstruimos el nombre base
        const baseName = fileNameParts.join('.');
        // Unimos las partes
        const newFileName = `${baseName}.${extension}`;

        // Creamos un nuevo objeto File con el mismo contenido
        const fileWithLowercaseExtension = new File([file], newFileName, { type: file.type });

        // Lo agregamos a arreglo de Files
        this.files.push(fileWithLowercaseExtension);
        this.updateFormGroup(filesControl);
      };

      reader.readAsDataURL(file);
    }
  }
  /**
   * Función para actualizar el formulario con los archivos seleccionados
   * @param filesControl
   */
  public updateFormGroup(filesControl: AbstractControl | null) {
    if (filesControl) {
      if (this.files.length > 0) {
        filesControl.setValue(this.files);
        filesControl.setErrors(null);
        this.updateName();
      } else {
        filesControl.setErrors(null);
      }
      this.updateName();
    }
  }
  /**
   * Función para actualizar el nombre de los archivos cargados
   */
  public updateName() {
    const fileCount = this.files.length;
    const pluralSuffix = fileCount !== 1 ? 's' : '';
    this.BehaviorSubjectMessageSelectFile.next(`Cargaste ${fileCount} archivo${pluralSuffix}`);
  }
  /**
   * Restablecer el estado de los archivos a su forma inicial
   */
  public resetFileState() {
    // Restablecer el estado a su forma inicial
    this.validateFiles = false;
    this.files = [];

    const filesControl = this.formGroup.get(this.filesFieldname);
    if (filesControl) {
      filesControl.setValue([]);
      filesControl.updateValueAndValidity();
    }
  }
  /**
   * Función para eliminar un archivo de la lista de archivos cargados
   */
  public removeFile(index: number) {
    this.files.splice(index, 1);
    const filesControl = this.formGroup.get(this.filesFieldname);
    this.updateFormGroup(filesControl);

    // Actualizar el nombre después de la eliminación
    this.updateName();

    // Verificar si la lista de archivos está vacía después de la eliminación
    if (this.files.length <= 0) {
      this.BehaviorSubjectMessageSelectFile.next('No hay archivos seleccionados');
      this.resetFileState();
    } else {
      this.updateName();
    }
  }
  /**
   * @description Método para verificar cuales controles son inválidos
   */
  public findInvalidControls(): string[] {
    const invalidControls: string[] = [];
    for (const name of Object.keys(this.formGroup.controls)) {
      if (this.formGroup.controls[name].invalid) {
        invalidControls.push(name);
      }
    }

    return invalidControls;
  }

  /**
   * @description Método para atender el Submit del formulario
   */
  public onSubmitGe(): boolean {
    this.spinner.show();
    parent.window.utag.link({
      tealium_event: 'click',
      eventLabel: 'enviar',
      eventCategory: 'formulario_nexa',
    });

    const invalidControls = this.findInvalidControls();
    this.errorTextRequestSubmit = false;
    if (invalidControls.includes('textRequest')) {
      this.errorTextRequestSubmit = true;
    }

    if (invalidControls.length > 0) {
      this.spinner.hide();

      return false;
    }

    const data: CaptchaDto = {
      googletoken: this.formGroup.value.recaptchaReactiveGe,
    };

    this.coreFacade.postRecaptchaGe(data);

    return true;
  }

  /**
   * Metodo doRecaptchaReceived
   */
  public doRecaptchaReceivedGe(reCaptchaGe: ReCaptchaDto) {
    this.spinner.show();
    this.tryRecaptcha = true;
    let formModel: FormModel;
    this.validRecaptcha$.next(reCaptchaGe.success || false);
    if (reCaptchaGe.isValidAuthentication) {
      const formGroupValue = this.formGroup.value;
      const phoneGe = Number(formGroupValue.phone);
      formModel = {
        document: formGroupValue.document,
        company: 'Banco de Occidente',
        idCompany: 1,
        typeClient: 'Gerente Elite',
        idTypeClient: 4,
        textRequest: formGroupValue.textRequest,
        product: 'Gerente Elite',
        typeOfRequest: formGroupValue.typeOfRequest,
        name: '',
        email: formGroupValue.email,
        documentClient: formGroupValue.documentClient,
        phone: phoneGe,
        idMailBox: formGroupValue.idMailBox,
        idTemplate: formGroupValue.idTemplate,
        idAttachmentMode: formGroupValue.idAttachmentMode,
        attachment: TypeAttach[formGroupValue.idAttachmentMode],
      };

      // Se pasa la informacion al objeto
      const sendEmail: SendEmailDto = {
        form: JSON.stringify(formModel),
        files: this.files,
        isManagerElite: true,
      };

      const dataModel: FormManagerElite = {
        NumberDocument: formGroupValue.document,
        Name: formGroupValue.name || '',
        Email: formGroupValue.email || '',
        DocumentClient: formGroupValue.documentClient,
        Phone: phoneGe,
        TypeClient: 'Gerente Elite',
        TypeRequest: formGroupValue.typeOfRequest,
        Attachments: this.files.length > 0 ? true : false,
        MessageClient: formGroupValue.textRequest,
        CountAttachments: this.files.length,
        Company: 'Banco de Occidente',
        IsClient: true,
        IdClient: formGroupValue.company === 1 ? environment.occidenteIdClient : environment.fiduoccidenteIdClient,
        IdCampaign:
          formGroupValue.company === 1 ? environment.occidenteIdCampaign : environment.fiduoccidenteIdCampaign,
      };

      const interactionModel: Interaction = {
        IdInteraction: 1,
        GuId: Guid.create().toString(),
        FormManagerElite: dataModel,
        Date: new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString(),
      };

      const interactionDto: InteractionDto = {
        payload: `${JSON.stringify(interactionModel)}`.trim(),
        version: '1',
        idSequence: 2,
        idFiling: 1,
        publishDate: new Date(),
        typeForm: 3,
      };

      // Se realizar del envio de correo
      setTimeout(() => {
        this.coreFacade.saveInteraction(interactionDto, (responseInteraction) => {
          if (responseInteraction !== 'false') {
            sendEmail.radicado = responseInteraction;
            this.coreFacade.postSendEmail(sendEmail, () => {
              this.spinner.hide();
            });
          }
        });
      }, 100);

      this.formGroup.reset();
    }
  }
}
