<div fxLayout.gt-md="row wrap" fxLayout.lt-lg="column" fxLayoutGap="0px">
  <div fxFlex.gt-md="65" fxFlex.lt-lg="100">
    <img class="occidente" src="./../../../../assets/img/conciliacion_landing banner.jpg" alt="" />
  </div>
  <div fxFlex.gt-md="32" fxFlex.lt-lg="100">
    <div class="padding-form" style="padding-top: 40px !important">
      <div fxLayout="row wrap">
        <div fxFlex="100">
          <h5 class="sub-title-customer-1 text-secondary font-weight-bold ml-1">Tu Banco te escucha</h5>
          <h5 class="sub-title-customer-2 text-secondary ml-1">Diligencia tus datos</h5>
          <h5 class="sub-title-customer-3 ml-1 text-primary mb-3">* Todos los campos son obligatorios</h5>
        </div>
        <div fxFlex="100">
          <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
            <div fxLayout="column">
              <!--Documento-->
              <mat-form-field fxFlex="100" hideRequiredMarker>
                <div class="form-group active">
                  <mat-label class="label">Número de Documento*</mat-label>
                  <input
                    class="mat-input-element-input"
                    matInput
                    type="text"
                    minlength="5"
                    maxlength="12"
                    formControlName="document"
                    placeholder="Digitos permitidos Min(5) - Max(12)."
                    [required]="formGroup.controls.document.invalid"
                    autocomplete="off"
                    (keypress)="numberOnly($event)"
                    (change)="validateClient()"
                  />
                </div>
              </mat-form-field>
              <br *ngIf="this.isClient">
              <div class="alert" *ngIf="this.isClient">
                <h5 class="sub-title-customer-4 text-secondary ml-1" *ngIf="this.isClient">
                  A través de nuestros canales podremos mantenerte informado del estado de tus productos, fechas de pago, brindarte alternativas de normalización, confirmación del estado de tus solicitudes, entre otros, para que sigas disfrutando de los beneficios que tenemos para ti.
                  <br><br>Selecciona en cada lista una de las opciones
                </h5>
              </div>
              <div class="alert" *ngIf="!this.isClient && !this.init">
                <h5 class="sub-title-customer-4 text-secondary ml-1" *ngIf="!this.isClient && !this.init">
                  Creo que aún no eres nuestro cliente. Por favor verifica que tu número de documento este correcto para
                  poder ayudarte o comunícate con nuestra línea de servicio en Bogotá (601) 390 20 58 y para el resto
                  del país al 018000 514 652.
                </h5>
              </div>
              <!--Canal de Contacto-->
              <mat-form-field fxFlex="100" hideRequiredMarker *ngIf="this.isClient">
                <div class="form-group active">
                  <mat-label class="label">Selecciona tus canales</mat-label>
                  <mat-select formControlName="contactChannel" [required]="formGroup.controls.contactChannel.invalid">
                    <mat-option *ngFor="let contactChannel of this.contactChannel" [value]="contactChannel">
                      {{ contactChannel.value }}
                    </mat-option>
                  </mat-select>
                </div>
              </mat-form-field>
              <!--Canal de Informativo-->
              <mat-form-field fxFlex="100" hideRequiredMarker *ngIf="this.isClient">
                <div class="form-group active">
                  <mat-label class="label">Selecciona otros canales</mat-label>
                  <mat-select
                    formControlName="informationChannel"
                    [required]="formGroup.controls.informationChannel.invalid"
                  >
                    <mat-option *ngFor="let informationChannel of this.informationChannel" [value]="informationChannel">
                      {{ informationChannel.value }}
                    </mat-option>
                  </mat-select>
                </div>
              </mat-form-field>
              <br>
            </div>
            <div class="div-content" *ngIf="this.isClient" style="width: 100%">
              <h5 class="sub-title-customer-6 text-secondary ml-1">
                Actualiza tu correo electrónico y dirección comunicándote a nuestra línea de servicio al cliente en Bogotá al <b>601 3902058</b>, a nivel nacional el <b>01 8000 514652</b> o a nivel internacional al <b>01 800 45 38044</b>. Recuerda que solo podrás actualizar tu número de celular en nuestras oficinas.
              </h5>
            </div>
            <br *ngIf="this.isClient">
            <h5 class="sub-title-customer-7 text-secondary font-weight-bold ml-1" *ngIf="this.isClient">
              Estamos de tu lado cuando más lo necesitas
            </h5>
            <h5 class="sub-title-customer-4 text-secondary ml-1" *ngIf="this.isClient">
              Al continuar nos autorizas a que tus datos sean tratados conforme a la ley 1581 del 2012 de protección de
              datos que se encuentra publicado en nuestra página web.
            </h5>

            <div *ngIf="this.isClient">
              <br>
              <re-captcha class="g-recaptcha center-align" formControlName="recaptchaReactive"></re-captcha>
              <mat-error *ngIf="!(validRecaptcha$ | async) && tryRecaptcha"> ¡El reCaptcha es Inválido! </mat-error>
              <br>
            </div>

            <button mat-raised-button [disabled]="formGroup.invalid" class="button-border">
              <p [ngClass]="formGroup.invalid ? 'button button-disabled' : 'button button-primary'">Enviar</p>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(107, 109, 134, 0.68)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p class="spinner-text">Cargando...</p>
</ngx-spinner>
