import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

/**
 * Componente del modal de gerente elite
 */
@Component({
  selector: 'app-modal-elite-manager',
  templateUrl: './modal-elite-manager.component.html',
  styleUrls: ['./modal-elite-manager.component.scss'],
})
export class ModalEliteManagerComponent implements OnInit {
  /**
   * Diálogo Popup con resultado del envío
   */
  public dialogPopup: MatDialog;
  public constructor(dialogPopup: MatDialog) {
    this.dialogPopup = dialogPopup;
  }

  /**
   * Ng On Init
   */
  public ngOnInit(): void {}

  /**
   * Metodo que cierra el modal
   */
  public popUp() {
    this.dialogPopup.closeAll();
  }
}
